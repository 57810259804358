<template>
  <div class="box" v-html="dataInfo.content">
<!--    <img src="../../../assets/img/new/gaotie.png" width="100%" alt="">-->
<!--    <div class="box">-->
<!--      <div class="box-1">-->
<!--        无锡的高铁站：无锡东站-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {getBannerowInfo} from "@/api/table";

export default {
  name: "gaotie",
  props:['info'],
  data(){
    return {
      tType:'CH',
      dataInfo:{}
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    // this.dataInfo = this.info
    this.createList()
  },
  methods:{
    createList(){
      getBannerowInfo({
        bannerType:'8',   // 必填 banner类型：1度假区概况 2度假区总览图 3交通指引 4官网首页轮播图 5交通指引（公交、高铁、飞机）6首页度假区概况
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.dataInfo = res.rows[0]
          // this.totalCount=res.total
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.box{
  width: 100%;
  //height: 327px;
  margin-bottom: 120px;
  padding-bottom: 30px;
  align-items: center;
  border-radius: 0 0 20px 20px;
  background: #FFFFFF;
  display: block;
  overflow: hidden;
  img{
    max-width: 100%;
  }
  .box-1{
    padding: 59px 50px;
    box-sizing: border-box;
    font-size: 20px;
    color: #333333;
  }
}
</style>
